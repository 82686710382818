
function lazyLoadLayout(theme: string) {
  return import(
    /* webpackChunkName: "enlay" */ './theme/' + theme + '/pgEntry_layout.vue'
  )
}

import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'PageEntryLayout'
})
export default class PageEntryLayout extends Vue {
  get realLayout() {
    return () => lazyLoadLayout(process.env.VUE_APP_THEME)
  }
}
